<template>
  <v-dialog v-model="dialog" persistent width="300">
    <v-card :color="$store.state.appThemeColor" dark>
      <v-card-text>
        Loading...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import { ref } from '@vue/composition-api'

export default {
  name: 'LoadingDialog',
  beforeMount(){
    this.dialog = true
  },
  setup(){
    return {
      dialog: true
    }
  }
}
</script>
