<template>
  <div>
    <v-app-bar app :color="$store.state.appThemeColor" dark dense fixed>
      <slot name="toolbar"></slot>
    </v-app-bar>
    <v-main class="py-12">
      <div
        style="background-color: #fff; max-width:860px"
        class="mx-auto"
      >
        <v-card
          v-if="imgCover != '' && showImg == true"
          elevation="0"
          class="d-flex align-center justify-center mb-2"
        >
          <v-img
            :src="imgCover"
            :lazy-src="imgCover"
            max-height="200px"
            width="100%"
            position="top"
            class="mx-auto"
            aspect-ratio="1"
            cover
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
        <slot></slot>
      </div>
    </v-main>
  </div>
</template>

<script>
export default {
  props: {
    imgCover: {
      type: String,
      default: "",
    },
  },
  components: {
  },

  computed: {
    showImg() {
      // console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
      return false;
    },
  },
};
</script>