export default {
  data: () => ({
    loginInfo: {
      get username() {
        return localStorage.getItem('username');
      },
      set username(value) {
        localStorage.setItem('username', value);
      },
      get firstName() {
        return localStorage.getItem('firstName');
      },
      set firstName(value) {
        localStorage.setItem('firstName', value);
      },
      get lastName() {
        return localStorage.getItem('lastName');
      },
      set lastName(value) {
        localStorage.setItem('lastName', value);
      },
      get email() {
        return localStorage.getItem('email');
      },
      set email(value) {
        localStorage.setItem('email', value);
      },
      get profileImage() {
        return localStorage.getItem('profileImage');
      },
      set profileImage(value) {
        localStorage.setItem('profileImage', value);
      },
    },
  }),
  methods: {
    saveApiToken(token) {
      localStorage.setItem('apiToken', token);
      return localStorage.getItem('apiToken');
    },
    deleteApiToken() {
      localStorage.setItem('apiToken', '');
    },
    getLoginInfo() {
      var api_token = localStorage.getItem("apiToken");
      if (api_token == "undefined") {
        this.$store.state.isLoggedIn = false;
        return null;
      } else {
        this.apiGet("login/info")
          .then(res => {

            if (res.data.status == "success") {
              localStorage.setItem('username', res.data.data.username);
              localStorage.setItem('firstName', res.data.data.first_name);
              localStorage.setItem('lastName', res.data.data.last_name);
              localStorage.setItem('profileImage', res.data.data.user_img);

              console.log(res.data.data);

              return res.data.data;
            } else {
              localStorage.setItem('username', '');
              localStorage.setItem('firstName', '');
              localStorage.setItem('lastName', '');
              localStorage.setItem('profileImage', '');

              this.$store.state.isLoggedIn = false;
              return null;
            }
          })
          .catch(err => {
            console.log(err);
            localStorage.setItem('username', '');
            localStorage.setItem('firstName', '');
            localStorage.setItem('lastName', '');
            localStorage.setItem('profileImage', '');


            this.$store.state.isLoggedIn = false;
            return this.$store.state.loginInfo.null;
          });
      }
    },
    requiredLogin() {
      // this.apiGet("valid")
      //   .then(res => {
      //     if (res) {
      //       if (res.status == "success") {
      //         this.$store.state.isLoggedIn = true;
      //       } else {
      //         this.$router.push("/login");
      //       }
      //     } else {
      //       this.$router.push("/login");
      //     }

      //   })
      //   .catch(err => {
      //     console.log(err);
      //     this.$store.state.isLoggedIn = false;
      //   });

      if (this.$store.state.isLoggedIn == false){
        this.$router.push("/login");
      }
    },
    signOut() {
      this.$store.state.apiToken = "";
      this.$store.state.loginInfo.username = "";
      this.$store.state.loginInfo.firstName = "";
      this.$store.state.loginInfo.lastName = "";
      this.$store.state.loginInfo.profileImage = "";
      this.$store.state.isLoggedIn = false;
      this.$router.push("/login");
    },
  },
};
