<template>
  <v-bottom-navigation :value="$route.name" color="teal" fixed grow>
    <v-btn
      v-for="(item, i) in menus"
      :key="i"
      :to="{ name: item.name }"
      :value="item.value"
      class="full-height"
    >
      <span>{{ item.title }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data: () => ({
    menus: [
      {
        name: "Home",
        title: "Home",
        icon: "mdi-home",
        value: "Home",
      },
      {
        name: "Attendance",
        title: "Kehadiran",
        icon: "mdi-account-check",
        value: "Attendance",
      },
      {
        name: "Scores",
        title: "Nilai",
        icon: "mdi-book-open",
        value: "Scores",
      },
      {
        name: "Elibrary",
        title: "E-Perpus",
        icon: "mdi-book-open-page-variant",
        value: "Elibrary",
      },
      {
        name: "Account",
        title: "Akun",
        icon: "mdi-account",
        value: "Account",
      },
    ],
  }),
  mounted() {},
};
</script>

<style scoped>
.full-height {
  height: 56px !important;
}
</style>