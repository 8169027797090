<template>
  <div>
    <v-app-bar app :color="$store.state.appThemeColor" dark dense fixed>
      <slot name="toolbar"></slot>
    </v-app-bar>

    <v-main class="py-12">
      <div
        style="background-color: #fff; max-width:860px"
        class="mx-auto"
      >
        <slot></slot>
      </div>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: {},
};
</script>