import moment from 'moment'

export default {
  data: () => ({}),
  methods: {
    numberFormat(number, decimals, dec_point, thousands_sep) {
      if (typeof number === "undefined" || isNaN(number) == true) {
        return 0
      }
      // Strip all characters but numerical ones.
      number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return '' + Math.round(n * k) / k;
        };
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join(dec);
    },
    isMobileBrowser() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    setNavDrawerSetOp() {
      var t = this.$store.state.navDrawer;

      if (t == true) {
        this.$store.state.navDrawer = false;
      } else {
        this.$store.state.navDrawer = true;
      }
    },
    ucFirst(string) {
      if (typeof string !== 'undefined')
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      return "";
    },
    ucFirstAll(string) {
      if (typeof string == 'undefined') return "";

      var str = "";
      string.toLowerCase();

      const myArr = string.split(" ");
      if (typeof myArr !== 'undefined') {
        myArr.forEach(function (item) {
          if (typeof item !== 'undefined')
            str = str + ' ' + item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        });
      }

      return str;
    },
    appNotification(message = '', type_ = '') {
      this.$store.state.showAppNotification = true;
      this.$store.state.msgAppNotification = message;
      this.$store.state.typeAppNotification = type_;
    },
    asyncLoading(promise) {
      this.$store.state.showLoadingDialog = true;
      return promise.then(
        this.$store.state.showLoadingDialog = false
      )
    },
    numberToShortText(number) {
      if (number < 1000) {
        return this.numberFormat(number);
      }

      if ((number >= 1000) && (number < 1000000)) {
        return this.numberFormat(number / 1000) + "K"
      }

      if ((number >= 1000000)) {
        return this.numberFormat(number / 1000000) + "M"
      }
    },
    goTo(uri){
      return this.$router.push(uri);
    },
    externalUrl(url){
      return window.open(url, '_blank');
    },
    arrayUniqBy(a, f) {
      var rows = []
      var tmpF = []
      a.forEach(item => {

        if(tmpF.includes(item[f]) == false){
          rows.push(item)
        }

        
        tmpF.push(item)
      })

      return tmpF;
    },
    showAlert(type = '', message = ''){
      if (type == 'success') {
        this.$awn.success(message)
      } else if (type == 'info') {
        this.$awn.info(message)
      } else if (type == 'warning') {
        this.$awn.warning(message)
      } else if (type == 'error') {
        this.$awn.alert(message)
      } else {
        this.$awn.info(message)
      }
      
    },
    setPageTitle(title, icon=''){
      document.title = title;
      this.$store.state.pageIcon = icon
      this.$store.state.pageTitle = title
    },

    setDrawer(){
      this.$store.state.drawer = !this.$store.state.drawer;
    },

    appDateFormat(date) {
      var _date = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      if (_date === 'Invalid date') return ''
      return _date
    },
  }
};
