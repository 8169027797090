import '@/plugins/vue-composition-api'
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import '@/plugins/axios'
import VueAWN from "vue-awesome-notifications"

import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

import loginMixin from './mixins/loginMixin'
import helperMixin from './mixins/helperMixin'

// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.config.productionTip = false


Vue.use(VueAWN)
Vue.use(VueMoment, {
  moment,
})

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

Vue.mixin(loginMixin)
Vue.mixin(helperMixin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
