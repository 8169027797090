<template>
  <v-app>
    <component :is="resolveLayout"></component>
  </v-app>
</template>

<script>
import BlankLayout from './layouts/BlankLayout.vue'
import MainLayout from './layouts/MainLayout.vue'
import LoginLayout from './layouts/LoginLayout.vue'

export default {
  name: "App",

  components: { 
    MainLayout,
    BlankLayout,
    LoginLayout, 
  },

  computed:{
    resolveLayout(){
      // Handles initial route
      if (this.$route.name === null) return null

      if (this.$route.meta.layout === 'login') return 'router-view'
      if (this.$route.meta.layout === 'blank') return 'blank-layout'
      if (this.$route.meta.layout === 'main') return 'main-layout'

      return 'router-view'
    },
  },
};
</script>

<style>
@import "~vue-awesome-notifications/dist/styles/style.css";

/* @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap");
@font-face {
  font-family: "Comfortaa", cursive;
  src: url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap");
}
* {
  font-family: "Comfortaa", cursive !important;
} */
</style>
